import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Title from '../../Title/Title'
import './HomeFAQs.scss'
import { Faq } from '../../FAQs/faqs-list-interface'
import FAQ from '../../FAQs/FAQ'
import useFaqExpansion from '../../../hooks/useFaqExpansion'
import { POKER_FAQS,FAQS } from '../../internal-links'
import { Container } from 'react-bootstrap'

interface Props {
  faqs?: Faq[]
  allFaqText?: string
  hideAllFaqsLink?: boolean
  faqTitle?: string
  color?: string
  backgroundColor?: string
  containerStyles?: React.CSSProperties
  showClubs?: boolean // New prop to control the display of clubs
  style?: React.CSSProperties
  h2LineHeight?: string;
}

const HomeFAQs: React.FC<Props> = ({
  faqs,
  allFaqText,
  faqTitle,
  hideAllFaqsLink,
  color,
  backgroundColor,
  containerStyles,
  showClubs = false, // Default to false
  style, // New padding prop
  h2LineHeight = 'normal',
}) => {
  const { expandedFaq, setExpandedFaq } = useFaqExpansion()
  const [defaultFaqs, setDefaultFaqs] = useState<Faq[]>([])

  useEffect(() => {
    import('../../FAQs/faqs-list').then(({ default: dfaq }) => {
      return setDefaultFaqs(dfaq.slice(0, 5))
    })
  }, [])

  const faqsList = faqs?.length ? faqs : defaultFaqs

  // Calculate padding style
  const isBrowser = typeof window !== 'undefined';

  const faqlink = isBrowser && window.location.pathname.startsWith('/poker')
    ? POKER_FAQS
    : FAQS;

  return (
    <div
      id="FAQ"
      className={showClubs ? 'show-clubs' : ''}
      style={{
        ...containerStyles,
        ...style, // Apply padding styles here
        background: backgroundColor,
      }}
    >
      <Container>
      <h2 style={{ lineHeight: h2LineHeight }}>{faqTitle || 'FAQ'}</h2>
        <div className="content-container">
          <div className="content">
            <div className="faqs">
              {faqsList.map((f, i) => {
                return (
                  <React.Fragment key={f.question}>
                    <FAQ
                      question={f.question}
                      answer={f.answer}
                      answerElement={f.answerElement}
                      expanded={expandedFaq === i}
                      setExpanded={setExpandedFaq}
                      questinNumber={i}
                      color={color}
                    />

                    {i !== faqsList.length - 1 ? (
                      <div className="separator" />
                    ) : null}
                  </React.Fragment>
                )
              })}
            </div>

            {!hideAllFaqsLink && (
              <Link to={faqlink} className="cta">
                <div>{allFaqText || 'GO TO FAQ PAGE'}</div>

                <div className="right-icon">
                  <StaticImage
                    src="../../../images/index/common/arrow-right.png"
                    alt="more faqs"
                    className="arrow"
                    width={24}
                  />
                </div>
              </Link>
            )}
            <div className="chip-left">
              <StaticImage
                src="../../../images/index/common/chip-left.png"
                alt="chip left"
                className="chip-img"
                quality={100}
              />
            </div>
            <div className="chip-right">
              <StaticImage
                src="../../../images/index/common/chip-right.png"
                alt="chip right"
                className="chip-img"
                quality={100}
              />
            </div>
            <div className="club-left">
              <StaticImage
                src="../../../images/index/common/club.png"
                alt="club left"
                className="club-img"
                quality={100}
              />
            </div>
            <div className="club-right">
              <StaticImage
                src="../../../images/index/common/club.png"
                alt="club right"
                className="club-img"
                quality={100}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HomeFAQs
