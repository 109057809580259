import React, { useEffect, useState } from 'react'

import './Poker.scss'

import HomeFAQs from '../../../Index/HomeFAQs/HomeFAQs'
import TrustFlowWidget from '../../../Trust-Flow-Widget/Trust-flow-widget'

import StaySafeAndCertified from '../../../Design2.0/common/StaySafeAndCertified/StaySafeAndCertified'
import CommonTerms from '../../../Design2.0/PokerPage/Features/CommonTerms/CommonTerms'
import BannerCarousel from '../../../Design2.0/HomePage/Features/Carousel/BannerCarousel'
import TypesDescription from '../../../Design2.0/PokerPage/Features/TypesofPoker/TypesDescription'
import MixedTypesofGames from '../../../Design2.0/PokerPage/Features/MixedGames/MixedTypesofGames'
import TypeofCardGames from '../../../Design2.0/PokerPage/Features/TypeofCardGames/TypeofCardGames'
import TestimonialsContent from '../../../Design2.0/common/Testimonials/Testimonials'
import OurBlogs from '../../../Design2.0/common/OurBlogs/OurBlogs'
import AboutGetMega from '../../../Design2.0/HomePage/AboutGetMega/AboutGetMega'
import { faqsHindi } from '../../../../components/FAQs/faqs-list'
import TypesOfPoker from '../../../Design2.0/PokerPage/Features/TypesofPoker/TypesOfPoker'
import HeaderSection from '../../../Design2.0/PokerPage/Features/TypesofPoker/HeaderSection'
import FeaturesSection from '../../../Design2.0/PokerPage/Features/TypesofPoker/FeatureSection'
import PokerMainBanner from '../../../Design2.0/PokerPage/PokerMainBanner'

interface Props {
  lang?: 'eng' | 'hindi'
  htmlContent?: React.ReactNode
  mainBannerTitle?: string
}
const Poker: React.FC<Props> = ({ lang, htmlContent, mainBannerTitle }) => {
  return (
    <div id="poker">
      <>
        <PokerMainBanner lang="hindi" />
        <TrustFlowWidget lang="hindi" />
        <HeaderSection lang={'hindi'} />
        <FeaturesSection lang="hindi" />
        <TypesOfPoker lang="hindi" />
        <BannerCarousel lang="hindi" />
        <StaySafeAndCertified lang="hindi" />
        <CommonTerms lang="hindi" />
        <TypesDescription lang="hindi" />
        <MixedTypesofGames lang="hindi" />
        <TypeofCardGames lang="hindi" />
        <TestimonialsContent
          lang="hindi"
          style={{ padding: '20px 0px' }}
          titleHindi="पोकर समीक्षा और रेटिंग्स"
        />
        <HomeFAQs
          faqTitle="पोकर के पूछे जाने वाले प्रश्न"
          faqs={faqsHindi.slice(0, 5)}
          allFaqText="एफएक्यू पेज पर जाएं"
          showClubs={true}
          style={{ paddingTop: '20px', paddingBottom: '30px' }}
        />
        <OurBlogs
          title={lang === 'hindi' ? 'पोकर ब्लॉग्स' : 'Poker Blogs'}
          lang="hindi"
          style={{ paddingTop: '20px', paddingBottom: '20px' }}
        />
        <AboutGetMega lang="hindi" />
      </>
    </div>
  )
}

export default Poker
