import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import PokerPage from '../../../components/Games/Cards/Poker/Poker'
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs'
import {
  POKER_HINDI,
  HINDI_HOME,
  POKER as POKER_LINK,
} from '../../../components/internal-links'
import { POKER } from '../../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../../lib/generate-schema'
import { Faq } from '../../../components/FAQs/faqs-list-interface'
import { pokerOneLinksPokerPage } from '../../../components/one-links'

const Poker: React.FC = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )
  const SITE_URL = data.site.siteMetadata.siteUrl

  const [pokerFaqsSt, setPokerFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../components/FAQs/faqs-list').then(({ pokerFaqs }) =>
      setPokerFaqsSt(pokerFaqs)
    )
  }, [])

  return (
    <Layout
      apkLink={POKER}
      showPokerForm
      pokerOneLink={pokerOneLinksPokerPage}
      lang="hindi"
      customLogo
      langPost={[
        {
          languageName: 'english',
          pageSlug: `${SITE_URL}${POKER_LINK}`,
        },
        {
          languageName: 'hindi',
          pageSlug: `${SITE_URL}${POKER_HINDI}`,
        },
      ]}
      headerStyles={{
        backgroundColor: 'transparent',
      }}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
        <link
          rel="alternate"
          href={`${SITE_URL}${POKER_LINK}`}
          hrefLang="en-in"
        />
        <link
          rel="alternate"
          href={`${SITE_URL}${POKER_LINK}`}
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href={`${SITE_URL}${POKER_HINDI}`}
          hrefLang="hi-in"
        />
      </Helmet>
      <SEO
        title="भारत में रियल कैश के लिए खेलें ऑनलाइन Mega पोकर  | 1 करोड़ तक जीतें  | Mega"
        description="Mega पर ऑनलाइन पोकर खेलें - भारत का बेस्ट रियल मनी गेमिंग एप्प है
        । ₹0.1 से ₹200 के बिग ब्लाइंड्स के साथ, यहां सभी के लिए एक टेबल है।"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(pokerFaqsSt)}
        hideHrefLang
      />
      <PokerPage lang="hindi" />
    </Layout>
  )
}

export default Poker
